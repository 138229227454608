import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import img1 from "../images/img1.png";
import man1 from "../images/ali.png";
import man2 from "../images/Habiba.png";
import man3 from "../images/umer.png";
import man4 from "../images/marium.png";
import man5 from "../images/jahanzaib.png";

const Teamslider = () => {
    const settingsTestimonial = {
        dots: true,
        infinite: true,
        speed: 100,
        slidesToShow: 5,
        arrows: false,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
        ]
    };

    const teamData = [
        {
          name: "Muhammad Ali Abbas",
          designation: "Founder & CEO",
          profile: img1,
          imageicon: man1,
        },
        {
          name: "Habiba Rehman",
          designation: "Robotics Engineer",
          profile: img1,
          imageicon: man2,
        },
        {
          name: "Umer Noorani",
          designation: "Embedded Robotics Engineer",
          profile: img1,
          imageicon: man3,
        },
        {
          name: "Mariyam Sheikh",
          designation: "AI/ML Engineer",
          profile: img1,
          imageicon: man4,
        },
        {
          name: "Syed Jahanzaib Rafi",
          designation: "Chief Technology Officer",
          profile: img1,
          imageicon: man5,
        }
    ];

    return (
        <section className='testmonialSec1' id='sec8'>
            <div className='backteamcolor'>
                <div className="effect"></div>
                <div className="container">
                    <h4 className='pr secHeading text-center' id='teamour'>Our team</h4>
                    <h1 className='pr secHeading text-center' id='withour'>Meet with our awesome<br /> team</h1>
                    <div className="px-3">
                        <Slider {...settingsTestimonial}>
                            {teamData.map((item, key) => (
                                <div className="mb-4 p-3 animatable fadeInUp" key={key}>
                                    <TestCard 
                                        imageawsome={item.imageicon} 
                                        name={item.name} 
                                        desig={item.designation} 
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>

            <style jsx>{`
                .card-image {
                    width: 100%;
                    height: auto;
                    object-fit: cover; /* Ensures the image covers the area without stretching */
                }

                .testCard1 {
                    position: relative;
                    overflow: hidden;
                    text-align: center;
                    color: white; /* Sets default text color to white */
                }

                .profileDiv1 {
                    margin-top: 10px;
                }

                .name-text {
                    font-size: 18px;
                    font-weight: bold;
                    margin-top: 10px;
                    color: white; /* Ensures name text is white */
                }

                .designation-text {
                    font-size: 16px;
                    color: white; /* Ensures designation text is white */
                    margin-top: 5px;
                }
            `}</style>
        </section>
    );
}

const TestCard = ({ imageawsome, name, desig }) => {
    return (
        <div className="testCard1">
            <img className='img-fluid card-image' src={imageawsome} alt="review" />
            <div className="profileDiv1">
                <div className="name-text">{name}</div>
                <div className="designation-text">{desig}</div>
            </div>
        </div>
    );
}

export default Teamslider;
